import http from '../utils/http';

export default class GrammarFormsService {
  static search(term) {
    const encoded = encodeURIComponent(term);
    return http.get(`/api/grammar-forms?category=${encoded}`).then(response => response.data);
  }

  static create(payload) {
    return http.post('/api/grammar-forms', payload).then(response => response.data);
  }

  static getCategories() {
    return http.get('/api/grammar-forms/categories').then(response => response.data);
  }

  static delete(id) {
    return http.delete('/api/grammar-forms/' + id).then(response => response.data);
  }

  static get(id) {
    return http.get('/api/grammar-forms/' + id).then(response => response.data);
  }

  static update(id, payload) {
    return http.put('/api/grammar-forms/' + id, payload).then(response => response.data);
  }
}
