import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./app.scss";

import Search from "./pages/Search";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Entry from "./pages/Entry";

import { gapi } from "gapi-script";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/authToken";

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (localStorage.jwtToken) {
      setAuthToken(localStorage.jwtToken);
      const decoded = jwt_decode(localStorage.jwtToken);
      setCurrentUser(decoded);

      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem("jwtToken");
        setCurrentUser(null);
        setAuthToken(false);
        window.location.href = "/";
      }
    }

    if (!gapi.client) {
      function start() {
        gapi.client.init({
          clientId:
            "234549190153-pvniclq3tm648s4t9j5ofs9i4jkt3025.apps.googleusercontent.com",
          scope: "",
        });
      }
      gapi.load("client:auth2", start);
    }
  }, []);

  return (
    <BrowserRouter>
      <Header currentUser={currentUser} onSetCurrentUser={setCurrentUser} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about">
          <About currentUser={currentUser} />
        </Route>
        <Route exact path="/search" component={Search} />
        <Route path="/entry/:id">
          <Entry currentUser={currentUser} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
