import React, { useEffect, useState } from 'react';
import GrammarFormsService from '../../services/GrammarForms';
import _ from 'lodash';

import { Button, Grid, InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SearchContent from '../../components/SearchContent';


const KINGYEON = ['Elementary', 'Continuing', 'Advanced Korean'];
const KLEAR = ['Beginning', 'Intermediate', 'High Intermediate', 'Advanced'];

function Search() {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [kingyeon, setKingyeon] = useState('');
  const [klear, setKlear] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    GrammarFormsService.getCategories().then(categories => {
      setCategories(categories);
    });
  }, []);

  function onDropdownSelect(event, type) {
    const { value } = event.target;
    if (type === 'category') {
      setCategory(value);
    } else if (type === 'kingyeon') {
      setKingyeon(value);
    } else if (type === 'klear') {
      setKlear(value);
    }
  }

  return (
    <div className="kgf-Search">
      <Grid
        container
        spacing={0}
        direction="column"
        justify="center"
      >
        <h5>Search Parameters:</h5>
        <div className="kgf-Search-dropdowns">
          <FormControl variant="standard" style={{ minWidth: 150, marginRight: 20 }}>
            <InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={category}
              onChange={(event) => onDropdownSelect(event, 'category')}
              label="Category"
            >
              {categories.map(c => <MenuItem key={c} value={c}>{c.trim()}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl variant="standard" style={{ minWidth: 300, marginRight: 20 }}>
            <InputLabel id="demo-simple-select-standard-label">King &amp; Yeon textbook and lesson #</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={kingyeon}
              onChange={(event) => onDropdownSelect(event, 'kingyeon')}
              label="King &amp; Yeon textbook and lesson #"
            >
              {KINGYEON.map(c => <MenuItem key={c} value={c}>{c.trim()}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl variant="standard" style={{ minWidth: 350, marginRight: 20 }}>
            <InputLabel id="demo-simple-select-standard-label">KLEAR textbook series level and lesson #:</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={klear}
              onChange={(event) => onDropdownSelect(event, 'klear')}
              label="KLEAR textbook series level and lesson #:"
            >
              {KLEAR.map(c => <MenuItem key={c} value={c}>{c.trim()}</MenuItem>)}
            </Select>
          </FormControl>
          <Button startIcon={<SearchIcon />} className="kgf-Search-button" variant="outlined" size="small">Search</Button>
        </div>
        <br />
        <SearchContent isLoading={isLoading} items={items} />
        <br />
      </Grid>
    </div>
  );
}

export default Search;
