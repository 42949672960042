import React from 'react';
import GoogleLogin from 'react-google-login';
import http from '../../utils/http';
import jwt_decode from 'jwt-decode';
import { GoogleLogout } from 'react-google-login';
import setAuthToken from '../../utils/authToken';

export default function Login(props) {
    const onLogin = googleData => {
      http.post("/auth/login", googleData.profileObj).then((res) => {
        const { token } = res.data;

        localStorage.setItem('jwtToken', token);

        setAuthToken(token);

        const decoded = jwt_decode(token);
        props.onSetCurrentUser(decoded);
        console.log(decoded);
      })
    };

    const onFailure = res => {
      console.error('Failed to login');
    }

    const logout = () => {
      localStorage.removeItem('jwtToken');
      props.onSetCurrentUser(null);
      setAuthToken(false);
    }

    return props.currentUser ? 
      <GoogleLogout
        clientId={process.env.GOOGLE_CLIENT_ID}
        buttonText="Logout"
        onLogoutSuccess={logout}
        onLogoutFailure={err => console.log(err)}
      /> :
      <GoogleLogin
          clientId={process.env.GOOGLE_CLIENT_ID}
          buttonText="Log in with Google"
          onSuccess={onLogin}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
      />
}