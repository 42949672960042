import React, { useState } from 'react';
import { Backdrop, Modal, FormControl, InputLabel, MenuItem, Select, Button, Box, TextField, Tooltip } from '@material-ui/core';
import { DefaultEditor } from 'react-simple-wysiwyg';
import CreatableSelect from 'react-select/creatable';
import RemoveIcon from '@material-ui/icons/CancelRounded';
import AddIcon from '@material-ui/icons/AddBoxOutlined';
import config from '../../config';
import _ from 'lodash';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fbfcfd',
    border: '2px solid #eee',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    height: 600,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
};

function EntryModal({ onSubmit, onClose, open, text, data }) {
    const [entryName, setEntryName] = useState(data?.entryName || '');
    const [typicalExample, setTypicalExample] = useState(data?.typicalExample || '');
    const [category, setCategory] = useState(data?.category || config.CATEGORIES[0]);
    const [categoryKorean, setCategoryKorean] = useState(data?.categoryKorean || '');
    const [categoryEnglish, setCategoryEnglish] = useState(data?.categoryEnglish || '');
    const [translation, setTranslation] = useState(data?.translation || '');
    const [sentences, setSentences] = useState(data?.sentences || [{ 'kor': '', 'eng': ''}]);
    const [links, setLinks] = useState(data?.links || []);

    function onChangeText(fieldName, value) {
        if (fieldName === 'entryName') {
            setEntryName(value);
        } else if (fieldName === 'typicalExample') {
            setTypicalExample(value)
        } else if (fieldName === 'categoryKorean') {
            setCategoryKorean(value);
        } else if (fieldName === 'categoryEnglish') {
            setCategoryEnglish(value);
        } else if (fieldName === 'translation') {
            setTranslation(value);
        }
    }

    function onChangeExample(index, field, value) {
        const clonedSentences = _.clone(sentences).map((s, i) => {
            if (i === index) {
                s[field] = value;
            }
            return s;
        });
        setSentences(clonedSentences);
    }

    function onCategorySelect(event) {
        setCategory(event.target.value);
    }
    
    function onTagSelectorChange(newValue) {
        setLinks(newValue);
    }

    function handleSubmit() {
        let modifiedSentences = sentences;
        let modifiedLinks = [];
        if (sentences.length) {
            modifiedSentences = modifiedSentences.filter(s => !!s.kor.trim());
        }
        if (links) {
            modifiedLinks = links.map(l => l.value);
        }

        onSubmit({
            entryName,
            category,
            typicalExample,
            categoryKorean,
            categoryEnglish,
            links: modifiedLinks,
            sentences: modifiedSentences,
            translation
        });
        onClose();
    }

    function addSentence() {
        const clonedSentences = _.clone(sentences);
        clonedSentences.push({ kor: '', eng: '' });
        setSentences(clonedSentences);
    }

    function removeSentence(event, index) {
        event.preventDefault();
        let clonedSentences = _.clone(sentences);
        clonedSentences = clonedSentences.filter((s, i) => i !== index);
        setSentences(clonedSentences);
    }

    return (
        <Modal
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            open={open}
            onClose={onClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{ ...style, width: 800 }}>
                <h4 id="child-modal-title">{text} Entry</h4>
                <TextField size="small" id="outlined-basic" label="Entry Name" variant="outlined" onChange={(event) => onChangeText('entryName', event.target.value)} value={entryName} />
                <br />
                <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={category}
                        onChange={onCategorySelect}
                        label="Category"
                        size="small"
                    >
                    {config.CATEGORIES.map(c => <MenuItem key={c} value={c}>{c.trim()}</MenuItem>)}
                    </Select>
                </FormControl>
                <br />
                <FormControl variant="standard">
                    <label className="kgf-EntryModal-label">Typical Example</label>
                    <DefaultEditor value={typicalExample} onChange={(event) => onChangeText('typicalExample', event.target.value)} />
                </FormControl>
                <br />
                <TextField size="small" id="outlined-basic" onChange={(event) => onChangeText('categoryKorean', event.target.value)} label="Category Korean" variant="outlined" value={categoryKorean} />
                <br />
                <TextField size="small" id="outlined-basic" onChange={(event) => onChangeText('categoryEnglish', event.target.value)} label="Category English" variant="outlined" value={categoryEnglish} />
                <br />
                <FormControl variant="standard">
                    <label className="kgf-EntryModal-label">Translation</label>
                    <DefaultEditor value={translation} onChange={(event) => onChangeText('translation', event.target.value)} />
                </FormControl>
                <br />
                <FormControl variant="standard">
                    <label className="kgf-EntryModal-label">Example Sentences</label>
                    {sentences.map((sent, i) => (
                        <div key={i} className="kgf-EntryModal-sentenceContainer">
                            <div className="kgf-EntryModal-sentence">
                                <label className="kgf-EntryModal-label">Korean</label>
                                <DefaultEditor value={sent.kor} onChange={(event) => onChangeExample(i, 'kor', event.target.value)} />
                                <br />
                                <FormControl fullWidth>
                                    <TextField size="small" id="outlined-basic" label="English" variant="outlined" onChange={(event) => onChangeExample(i, 'eng', event.target.value)} value={sent.eng} />
                                </FormControl>
                            </div>
                            <div className="kgf-EntryModal-sentenceActions">
                                <Tooltip title="Add"><AddIcon onClick={addSentence} /></Tooltip>
                                {sentences.length > 1 && <Tooltip title="Remove"><RemoveIcon onClick={() => removeSentence(event, i)} /></Tooltip>}
                            </div>
                        </div>
                    ))}
                </FormControl>
                <br />
                <FormControl variant="standard">
                    <label className="kgf-EntryModal-label">Cross References</label>
                    <CreatableSelect
                        defaultValue={links}
                        placeholder="Type references"
                        isMulti
                        isCreatable
                        name="links"
                        onChange={onTagSelectorChange}
                        options={links}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </FormControl>
                <div className="kgf-EntryModal-actions">
                    <Button style={{backgroundColor: 'rgb(0, 87, 183)', color: 'white'}} variant="contained" type="primary" onClick={handleSubmit}>Save</Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            </Box>
        </Modal>
    );
}

function createExamples(sentences) {
    if (!sentences.length) {
        return '';
    }
    let examples = '';
    sentences.forEach(sen => {
        examples += sen.kor + '<br>';
        examples += sen.eng + '<br><br>';
    });
    return examples;
}

export default EntryModal;
