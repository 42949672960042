import React, { useEffect, useState } from 'react';
import './app.scss';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Search from './pages/Search';
import Header from './components/Header';
import Home from './pages/Home';
import Admin from './pages/Admin';
import { gapi } from 'gapi-script';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/authToken';
import Entry from './pages/Entry';


function App() {
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    if (localStorage.jwtToken) {
      setAuthToken(localStorage.jwtToken);
      const decoded = jwt_decode(localStorage.jwtToken);
      setCurrentUser(decoded);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem('jwtToken');
        setCurrentUser({});
        setAuthToken(false);
        window.location.href = '/';
      }
    }
    function start() {
      gapi.client.init({
        clientId: "234549190153-pvniclq3tm648s4t9j5ofs9i4jkt3025.apps.googleusercontent.com",
        scope: ""        
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Header currentUser={currentUser} onSetCurrentUser={setCurrentUser} />
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
        <Switch>
          <Route exact path="/search" component={Search} />
        </Switch>
        <Switch>
          <Route exact path="/admin" component={Admin} />
        </Switch>
        <Switch>
          <Route path="/entry/:id" children={<Entry currentUser={currentUser} />} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
