import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typography, Box, Button, Modal } from '@material-ui/core';
import loader from '../../assets/images/loader.gif';
import './_index.scss';
import { useParams, useHistory } from 'react-router-dom';
import GrammarFormsService from '../../services/GrammarForms';
import EntryModal from '../../components/EntryModal';
import _ from 'lodash';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Entry({ currentUser }) {
    const [item, setItem] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [openModal, setModalOpen] = useState(false);
    const { id } = useParams();
    const history = useHistory();

    function get() {
        GrammarFormsService.get(id).then((data) => {
            setItem(data);
            setLoading(false);
        });
    }
    useEffect(() => {
        get()
    }, [])

    function onDelete() {
        GrammarFormsService.delete(id).then(() => {
            history.push('/');
        }).catch(() => alert('Something went wrong, please try again.'));
        setConfirmationModal(false);
    }

    function onEdit(data) {
        setLoading(true);
        GrammarFormsService.update(id, data).then(() => {
            get();
        }).catch(() => alert('Something went wrong, please try again.'));
    }

    return (
        <div className="kgf-Entry">
            {isLoading && <img src={loader} width="150"/>}
            {item && <div key={item.entryName}>
                {currentUser?.isAdmin && (
                    <span className="kgf-Entry-actions">
                        <a onClick={() => setModalOpen(true)}>Edit</a>
                        <a onClick={() => setConfirmationModal(true)}>Delete</a>
                    </span>
                )}
                <Typography className="kgf-Entry-text" variant="h6">{item.entryName} <span dangerouslySetInnerHTML={{__html: item.typicalExample }} /></Typography>
                <div className="kgf-Entry-body">
                    <div className="kgf-Entry-category">{item.categoryKorean} {item.categoryEnglish}</div>
                    <br />
                    <h5>Translation:</h5>
                    <span dangerouslySetInnerHTML={{__html: item.translation }} />
                    <br />
                    <br />
                    <h5>Examples:</h5>
                    <ol>
                    {item.sentences.map(sentence => (
                        <div key={sentence.kor}>
                        <li>
                            <span dangerouslySetInnerHTML={{__html: sentence.kor }} />{" "}
                            <div dangerouslySetInnerHTML={{__html: sentence.eng }} />
                        </li>
                        </div>
                    ))}
                    </ol>
                    <div className="kgf-Entry-links">{(item.links || [])
                        .map((link, i) => link.id ? 
                            <><a target="_blank" href={`/entry/${link.id}`}>{link.value}</a> {i !== item.links.length - 1 ? ',' : ''} </> :
                            <><span>{link.value}</span> {i !== item.links.length - 1 ? ',' : ''} </>)}
                    </div>
                </div>
                <Modal
                    open={showConfirmationModal}
                    onClose={() => setConfirmationModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    closeAfterTransition
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h3">
                            Are you sure you want to delete {item.entryName}?
                        </Typography>
                        <div className="kgf-Entry-actions">
                            <Button style={{backgroundColor: 'rgb(0, 87, 183)', color: 'white'}} variant="contained" type="primary" onClick={onDelete}>Yes</Button>
                            <Button onClick={() => setConfirmationModal(false)}>Cancel</Button>
                        </div>
                    </Box>
                </Modal>
                <EntryModal open={openModal} text="Edit" data={item} onClose={() => setModalOpen(false)} onSubmit={onEdit} />
            </div>}
        </div>
    );
}

Entry.propTypes = {
    currentUser: PropTypes.object
};


export default Entry;
