import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Box,
  IconButton,
  CircularProgress,
  Paper,
  Tooltip,
  Button,
  Container,
} from "@mui/material";
import {
  BLANK_RICH_TEXT,
  ReadOnlyEditor,
  RichTextEditor,
  withReact,
  withHistory,
  createEditor,
} from "interline-slate";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";

function About({ currentUser }) {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(BLANK_RICH_TEXT);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const name = "kgf_about";

  useEffect(() => {
    const fetchAuth = () => {
      try {
        if (currentUser) {
          setIsStudent(!currentUser.isAdmin);
        } else {
          setIsStudent(true);
        }
      } catch (error) {
        console.error("Error decoding JWT:", error);
        setIsStudent(true);
      }
    };

    const fetchData = async () => {
      try {
        const response = await axios({
          method: "get",
          url: `https://korninterlinereader.asia.ubc.ca/api/editable/${name}`,
        });
        if (response.data && Array.isArray(response.data.data)) {
          setText(response.data.data);
        } else {
          setText(BLANK_RICH_TEXT);
        }
      } catch (error) {
        console.error(`Error fetching ${name} data:`, error);
        setText(BLANK_RICH_TEXT);
      } finally {
        setLoading(false);
      }
    };

    fetchAuth();
    fetchData();
  }, [currentUser]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      await axios({
        method: "post",
        url: "https://korninterlinereader.asia.ubc.ca/api/editable",
        data: { name, data: text },
      });
      setIsEditing(false);
    } catch (error) {
      console.error(`Error saving ${name} data:`, error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ px: 4, py: 6 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {loading ? (
          <Box sx={{ textAlign: "center", padding: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {isEditing ? (
              <RichTextEditor
                id="page-rte"
                initialValue={text}
                onChange={setText}
                editor={editor}
                MUI={require("@mui/material")}
              />
            ) : (
              <ReadOnlyEditor
                value={text}
                editor={editor}
                MUI={require("@mui/material")}
              />
            )}
            {!isStudent && (
              <Paper
                variant="outlined"
                sx={{ padding: 1, textAlign: "center" }}
              >
                <Tooltip title={isEditing ? "Quit Editor" : "Edit Page"}>
                  <IconButton
                    aria-label={`Edit ${name}`}
                    onClick={handleEditClick}
                    disabled={saving}
                  >
                    {isEditing ? <EditOffIcon /> : <EditIcon />}
                  </IconButton>
                </Tooltip>
                {isEditing && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={saving}
                    sx={{ marginLeft: 2 }}
                  >
                    {saving ? "Saving..." : "Save"}
                  </Button>
                )}
              </Paper>
            )}
          </>
        )}
      </Box>
    </Container>
  );
}

About.propTypes = {
  currentUser: PropTypes.object,
};

export default About;
