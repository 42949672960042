import React from 'react';
import banner from '../../assets/images/bg.jpg';
import { useRouteMatch } from 'react-router-dom';
import Login from '../Login'
import EntryModal from '../EntryModal';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '@material-ui/core';
import { useState } from 'react';
import GrammarFormsService from '../../services/GrammarForms';

const ROUTES = {
  home: '/',
  search: '/search',
  admin: '/admin'
};

function Header({ currentUser, onSetCurrentUser }) {
  const [openModal, setModalOpen] = useState(false);
  function isActive(route) {
    const match = useRouteMatch(route);
    return match?.isExact;
  }

  function onAdd(data) {
    GrammarFormsService.create(data).then((resp) => {
      console.log(resp);
      setModalOpen(false);
    });
  }

  return (
    <div className="kgf-Header">
      <img
        className="kgf-Header-bg"
        src={banner}
        alt=""
      />
      <div className="kgf-Header-content">
        <nav className="navbar navbar-expand-lg navbar-light">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-collapse collapse justify-content-between" id="navbarNav">
            <ul className="navbar-nav mr-auto">
              <li className={`nav-item ${isActive(ROUTES.home) ? 'active' : ''}`}>
                <a className="nav-link" href={ROUTES.home}>Home</a>
              </li>
              {/* <li className={`nav-item ${isActive(ROUTES.search) ? 'active' : ''}`}>
                <a className="nav-link" href={ROUTES.search}>Search</a>
              </li> */}
            </ul>
            <ul className="navbar-nav">
              {currentUser?.isAdmin &&
                <li className="nav-item">
                  <div className="kgf-Header-add" ><Tooltip title="Add New Entry"><AddIcon onClick={() => setModalOpen(true)} /></Tooltip></div>
                </li>
              }
              <li className={`nav-item ${isActive(ROUTES.login) ? 'active' : ''}`}>
                <Login currentUser={currentUser} onSetCurrentUser={onSetCurrentUser} />
              </li>
            </ul>
            <EntryModal open={openModal} text="Add" onClose={() => setModalOpen(false)} onSubmit={onAdd} />
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
